import React, { useEffect, useState, useLayoutEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../helpers/apiHelper/requestHelper";
import DailyAvg from "../../assets/images/1.gif";
import Launch from "../../assets/images/2.gif";

import moreButton from "../../assets/images/MoreButton.svg";
import mbls from "../../assets/images/mbls.png";
import TradeHistoryChart from "./TradeHistoryChart";
import { useNavigate } from "react-router-dom";
import DashboardSlider from "./DashboardSlider";
import TradeGraph from "../../assets/images/graph.svg";
import { Link } from "react-router-dom";
import { storeUser } from "../../redux/users/usersSlice";
import { setCurrentTab } from "../../redux/links/linksSlice";
import { simplifyTradingVolume } from "../../helpers/helper";

export default function DashboardStats({ member }) {
    const [stats, setStats] = useState({});
    const api = useApi();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((value) => value?.user?.user);

    const getUserStats = async () => {
        const response = await api("get", "stats/index");
        if (response?.status) {
            setStats(response?.data);
        }
        // else {
        //     toastify(response?.message);
        // }
    };

    console.log(user, "UserCMID")
    const updateUserBalnce = async () => {
        const response = await api("get", "stats/updateUserBalance", { id: user?._id });
        console.log(response, "UserUpdateBalance")
    };

    useEffect(() => {
        getUserStats();
        updateUserBalnce()
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: Adds smooth scrolling animation
        });
    }, []);

    // useLayoutEffect(() => {
    //    if(user?.firstVisit) {
    //     navigate("copyTrade")
    //    }
    // },[])

    const calculateBalance = () => {
        let totalBalance =
            parseInt(stats?.bybitBalance || "0") +
            parseInt(stats?.binanceBalance || "0");
        let weeklyBalance = stats?.balanceSinceLastWeek || 0;
        let total = (weeklyBalance / totalBalance) * 100;
        return `${(total || 0)}%`;
    };

    const calculatePotentialAccount = () => {
        let totalBalance =
            (stats?.bybitBalance || 0) + (stats?.binanceBalance || 0);

        let addition = parseInt((stats?.dailyAvgProfit || 0) * 365);
        let total = totalBalance + addition;

        let value = (total || 0);

        console.log(value, "thisvalue")

        if (value === 0) {
            return "0.00";
        } else if (value >= 1000000) {
            const millionValue = (value / 1000000).toString();
            return `$${millionValue.replace(/\.?0*$/, "") + "M"}`;
        } else if (value >= 1000) {
            const thousandValue = (value / 1000).toString();
            return `$${thousandValue.replace(/\.?0*$/, "") + "K"}`;
        } else {
            return `$${value}`;
        }
    };

    const handleAppLinks = (val) => {
        let link = null;

        if (val === "apple") {
            link = user?.memberCommunities[0]?.community?.appLinks?.ios;
        } else if (val === "android") {
            link = user?.memberCommunities[0]?.community?.appLinks?.android;
        } else if (val === "other") {
            link = user?.memberCommunities[0]?.community?.appLinks?.other;
        }

        if (link) {
            window.open(getUrl(link), "_blank");
        }
    };

    const getUrl = (url) => {
        if (url.startsWith("http")) {
            return url;
        } else {
            return `https://${url}`;
        }
    };


    const appLinks = user?.memberCommunities[0]?.community?.appLinks;


    return (
        <>
            <div className="stats_cards pb-5">
                <div className="statusTrading">
                    <p>
                        Status:{" "}
                        {user?.binanceCredentials?.isConnected ||
                            user?.bybitCredentials?.isConnected
                            ? "Trading"
                            : "Not Trading"}
                    </p>
                    <p>
                        {user?.binanceCredentials?.isConnected
                            ? "Connected to: Binance"
                            : user?.bybitCredentials?.isConnected
                                ? "Connected to: ByBit"
                                : user?.binanceCredentials?.isConnected &&
                                    user?.bybitCredentials?.isConnected
                                    ? "Connected to: Binance, ByBit"
                                    : "Not Connected"}
                    </p>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="mbl-100">
                        <div className="white_card">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <p className="text-truncate color-black  bold fs19 align-items-center">
                                    Your Trading Account
                                </p>
                                <h4 className="text-truncate color-black bold fs30">
                                    {"$"}
                                    {simplifyTradingVolume((
                                        (stats?.binanceBalance || 0) +
                                        (stats?.bybitBalance || 0)
                                    ))}
                                </h4>
                                {stats?.balanceSinceLastWeek == undefined ? "" : stats?.balanceSinceLastWeek != 0 ?
                                    <p className="text-truncate color-black">
                                        <span className="cyan-color plus fs15">
                                            {/* {calculateBalance()} */}
                                            {stats?.balanceSinceLastWeek || 0}
                                        </span>{" "}
                                        since last week ($
                                        {stats?.balanceSinceLastWeek || 0})
                                    </p>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mbl-100">
                        <div className="black_card">
                            <div className="d-flex h-100 justify-content-between align-items-center">
                                <div>
                                    <p className="text-truncate  bold fs19">
                                        Daily Avg. Profit
                                    </p>
                                    <h4 className="text-truncate bold fs30">
                                        {"$"}
                                        {simplifyTradingVolume((stats?.dailyAvgProfit || 0))}
                                    </h4>
                                    {stats?.averageProfitSinceLastWeek == undefined ? "" : stats?.averageProfitSinceLastWeek != 0 ?
                                        <p className="text-truncate">
                                            <span className="plus cyan-color fs15">
                                                {simplifyTradingVolume(
                                                    stats?.averageProfitSinceLastWeek || 0
                                                )}
                                                {"%"}
                                            </span>{" "}
                                            since last week
                                        </p>
                                        : ""
                                    }
                                </div>
                                <img
                                    className="w-60"
                                    src={DailyAvg}
                                    width="100px"
                                    alt="DailyAvg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="mbl-100">
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate  bold fs15">
                                    Winrate
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {simplifyTradingVolume(stats?.winRate || 0)}
                                    {"%"}
                                </h4>
                            </div>
                            <div className="smallBlack_card">
                                <p className="text-truncate  bold fs15">
                                    Avg. trade
                                </p>
                                <h4 className="text-truncate bold fs25">

                                    {simplifyTradingVolume(stats?.avgTrade || 0)}%
                                </h4>
                            </div>
                        </div>
                        <div className="black_card position-relative">
                            <p className="community_heading text-truncate  bold fs25">
                                Trade history
                            </p>
                            <p className="text-truncate medium">
                                Get a full overview
                            </p>
                            <img
                                className="graphWidth"
                                src={TradeGraph}
                                alt="graph"
                            />
                            {/* <TradeHistoryChart /> */}
                            <div className="arrowBtn moreButtonIcon"
                                onClick={() => {
                                    navigate("/member/performance", {
                                        state: {
                                            tab: "TradeHistory",
                                        },
                                    });
                                    dispatch(setCurrentTab("TradeHistory"))
                                }}
                            >
                                <img
                                    src={moreButton}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mbl-100">
                        <div className="black_card">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <p className="bold fs19">
                                    Potential trading account <br /> next year
                                </p>
                                <p className="text-truncate pb-1">
                                    (If avg. performance stays the same)
                                </p>
                                <h4 className="text-truncate cyan-color bold fs30">
                                    {/* {calculatePotentialAccount()}  */} ${simplifyTradingVolume(stats?.potentialTradingAccount || 0)}

                                </h4>
                            </div>
                        </div>
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card last-trade-card">
                                <p className="text-truncate  bold fs15">
                                    Last trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.lastTrade || 0)}%
                                </h4>
                            </div>
                            <div className="smallBlack_card trading-vol-group-card">
                                <p className="text-truncate  bold fs15">
                                    Total trades
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {parseInt(
                                        stats?.totalTrades || 0
                                    )}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="mbl-100">
                        <div className="black_card">
                            <div className="d-flex h-100 justify-content-center flex-column">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="community_heading text-truncate bold fs19">
                                            Performance YTD
                                        </p>
                                        <h4 className="text-truncate bold fs30">
                                            {simplifyTradingVolume(
                                                stats?.yearlyGrowth?.performance || 0
                                            )}
                                            %
                                        </h4>
                                        {stats?.thisWeekPnlPercentage == undefined ? "" : stats?.thisWeekPnlPercentage != 0 &&
                                            <p className="text-truncate">
                                                <span className="plus cyan-color fs15">
                                                    {" "}
                                                    {(
                                                        stats?.thisWeekPnlPercentage ||
                                                        0
                                                    )}
                                                    {"%"}
                                                </span>{" "}
                                                since last week
                                            </p>
                                        }
                                    </div>
                                    <img
                                        src={Launch}
                                        className="launch"
                                        width="60px"
                                        alt="launch"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbl-100">
                        <div className="black_card">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="community_heading text-truncate bold fs19">
                                            Monthly Growth
                                        </p>
                                        <h4 className="text-truncate bold fs25">
                                            ${simplifyTradingVolume(stats?.monthlyGrowth?.growth || 0)}

                                        </h4>
                                        {stats?.growthSinceLastMonth == undefined ? "" : stats?.growthSinceLastMonth != 0 ?
                                            <p className="text-truncate">
                                                <span className="plus cyan-color fs15">
                                                    {" "}
                                                    {simplifyTradingVolume(
                                                        stats?.growthSinceLastMonth ||
                                                        0
                                                    )}
                                                    {"%"}
                                                </span>{" "}
                                                since last month
                                            </p>
                                            : ""
                                        }
                                    </div>
                                    <img
                                        src={Launch}
                                        className="launch w-60"
                                        width="90px"
                                        alt="launch"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl w-100">
                    <div className="mbl-100 w-100">
                        {appLinks &&
                            (appLinks.ios !== "" || appLinks.android !== "") &&
                            <div className="white_card grey-background mbl position-relative download_height w-100">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="text-truncate color-black  bold fs19 community_heading mt-3 mb-2 lh-27">
                                            Download our <br className="d-lg-none d-md-none" /> app now
                                        </p>
                                        <div className="appBtns fs13 medium mt-lg-2 mt-md-2">
                                            {user?.memberCommunities[0]?.community
                                                ?.appLinks?.ios && (
                                                    <button
                                                        onClick={() =>
                                                            handleAppLinks("apple")
                                                        }
                                                    >
                                                        Apple
                                                    </button>
                                                )}
                                            {user?.memberCommunities[0]?.community
                                                ?.appLinks?.android && (
                                                    <button
                                                        onClick={() =>
                                                            handleAppLinks("android")
                                                        }
                                                    >
                                                        Android
                                                    </button>
                                                )}
                                            {user?.memberCommunities[0]?.community
                                                ?.appLinks?.other && (
                                                    <button
                                                        onClick={() =>
                                                            handleAppLinks("other")
                                                        }
                                                    >
                                                        Other
                                                    </button>
                                                )}
                                        </div>
                                    </div>
                                    <img src={mbls} alt="mbls" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="col-12 w-692 mx-auto mt-slider">
                <DashboardSlider />
            </div> */}
            {/* <Link
                className="text-dark d-flex align-items-center links"
                onClick={() => {
                    // localStorage.setItem(
                    //   "lastVisitedRoute",
                    //   window.location
                    //     .pathname
                    // );

                    localStorage.removeItem(
                        "token"
                    );
                    localStorage.removeItem(
                        "lastVisitedRoute"
                    );
                    localStorage.removeItem("rememberMe")
                    // localStorage.clear()
                    dispatch(
                        storeUser({
                            user: null,
                            token: null,
                        })
                    );


                    navigate("/login");
                }}
            >
                <i className="bi bi-box-arrow-right me-2"></i>
                Log Out
            </Link> */}
        </>
    );
}
