import React, { useEffect, useState } from "react";
import Pagination from 'react-bootstrap/Pagination';

export default function CustomPagination({ page, setPage, items, filtered }) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (filtered?.length > 0) {
      setPages(
        Array.from(
          { length: roundUpToLimit(filtered?.length, 10) / 10 || 1 },
          (_, index) => index + 1 // Start from 1
        )
      );
    } else {
      setPages(
        Array.from(
          { length: roundUpToLimit(items?.length, 10) / 10 || 1 },
          (_, index) => index + 1 // Start from 1
        )
      );
    }
  }, [filtered, items]);

  function roundUpToLimit(number, limit) {
    const remainder = number % limit;
    return remainder === 0 ? number : number + (limit - remainder);
  }

  const renderPaginationItems = () => {
    const totalPages = pages.length;
    const maxVisiblePages = 3; // Set max visible pages to 3
    const paginationItems = [];

    // Determine range of pages to display
    let startPage = Math.max(1, page - 1); // 1 page before the current page
    let endPage = Math.min(totalPages, page + 1); // 1 page after the current page

    // Adjust the range if near the start or end
    if (startPage <= 2) {
      startPage = 1; // Avoid showing page 2 separately
    }
    if (endPage >= totalPages - 1) {
      endPage = totalPages; // Avoid showing totalPages - 1 separately
    }

    // Show ellipsis and first page if startPage > 2
    if (startPage > 2) {
      paginationItems.push(
        <Pagination.Item key={1} active={page === 1} onClick={() => setPage(1)}>
          1
        </Pagination.Item>
      );
      paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
    }

    // Show range of pages around the current page
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={page === i} onClick={() => setPage(i)}>
          {i}
        </Pagination.Item>
      );
    }

    // Show ellipsis and last page if endPage < totalPages - 1
    if (endPage < totalPages - 1) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          active={page === totalPages}
          onClick={() => setPage(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <Pagination className="custom-pagination">
      <Pagination.Prev
        onClick={() => page > 1 && setPage(page - 1)}
        disabled={page === 1}
      >
        &laquo;

      </Pagination.Prev>
      {renderPaginationItems()}

      <Pagination.Next
        onClick={() => page < pages.length && setPage(page + 1)}
        disabled={page === pages.length}
      >
        &raquo;
      </Pagination.Next>
    </Pagination>
  );
}
