import React from 'react'
import Table from 'react-bootstrap/Table';
import download from "../../../assets/images/download.svg"
import CustomPagination from "../../../components/CustomPagination"
import { isMobile } from 'react-device-detect';

function BillingHistory() {
    return (
        <>
            <div className='billingBox'>
                <h2 className='billingHeding'>
                    Billing history
                </h2>
                <Table striped className="billingTable">
                    <thead>
                        <tr>
                            {isMobile ? <th>Plan</th> : <th>Subscription</th>}
                            <th>Month</th>
                            <th>Status</th>
                            <th>Renews</th>
                            <th>Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>$199</td>
                            <td>October</td>
                            <td className='text-green'>PAID</td>
                            {isMobile ? <td>01/11/2024</td> : <td>01/11</td>}
                            <td>
                                <button type='button' className='download-btn'>
                                    <img src={download} alt='' />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>$199</td>
                            <td>October</td>
                            <td className='text-green'>PAID</td>
                            {isMobile ? <td>01/11/2024</td> : <td>01/11</td>}
                            <td>
                                <button type='button' className='download-btn'>
                                    <img src={download} alt='' />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>$199</td>
                            <td>October</td>
                            <td className='text-green'>PAID</td>
                            {isMobile ? <td>01/11/2024</td> : <td>01/11</td>}
                            <td>
                                <button type='button' className='download-btn'>
                                    <img src={download} alt='' />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className='d-flex justify-content-lg-end justify-content-md-end justify-content-center pt-2'>
                    <CustomPagination
                        setPage="1"
                        page="1"
                        items="5"
                        filtered="1"
                    />
                </div>
            </div>
        </>
    )
}

export default BillingHistory