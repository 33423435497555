import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cross from "../../../assets/images/cross.svg";
import { useTour } from "@reactour/tour";
import OnboardingModal from "../../../components/modals/OnboardingModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading, setShowConnectionSuccessModal } from "../../../redux/loading/loadingSlice";
import { updateUser, setEditable } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import AddIcon from "../../../assets/images/addIcon.svg";
import ExchangeAccounts from "./ExchangeAccounts";
import RenderLinks from "../../../components/links/RenderLinks";
import closeIcon from "../../../assets/images/cancelBlack.svg";
import addIcon from "../../../assets/images/addIcon.png";
import infoIcon from "../../../assets/images/teir-info-icon.png";
import AuthLoader from "../../../components/loader/authLoader";
import del from "../../../assets/images/Delete.svg"
import edit from "../../../assets/images/Pen.svg"
import gCross from "../../../assets/images/green-corss.svg"
import DeleteMember from "../../../components/modals/DeleteMember";

function Subscription({ setTab, tab }) {
    const api = useApi();
    const user = useSelector((value) => value?.user?.user);
    const inputRef = useRef(null);
    const loaders = useSelector((state) => state?.loader);

    // useEffect(() => {
    //     if (inputRef.current) {
    //     inputRef?.current?.focus();
    //     }
    // }, []);

    //     useEffect(() => {
    //     const input = inputRef.current;
    //     if (input) {
    //       input.focus();

    //       // Ensure the keyboard opens on mobile devices
    //       const touchStartHandler = () => {
    //         input.focus();
    //         input.removeEventListener('touchstart', touchStartHandler);
    //       };
    //       input.addEventListener('touchstart', touchStartHandler);
    //     }
    //   }, []);

    const [subscription, setSubscription] = useState([
        {
            name: `${user?.leaderCommunity?.name} Subscription Tier 1`,
            monthlyfee: "",
            yearlyfee: "",
            tradingCapital: "",
        },
    ]);
    console.log(subscription, "subscription")
    // const [pair, setPair] = useState([]);
    const [estimatedAmount, setEstimatedAmount] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [show, setShow] = useState(false);
    const editable = useSelector((value) => value?.user?.editable);
    // const [editable, setEditable] = useState(true)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const numberRegex = new RegExp(`^[0-9]*$`);
    const [tagData, setTagData] = useState([]);
    const [editableIndex, setEditableIndex] = useState(null);
    const [tradingCapitalInfoIndex, setTradingCapitalInfoIndex] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleAddTier = (index) => {
        console.log(index, "TierIndex")
        setEditableIndex(index);
        dispatch(setEditable(false))
        setDeleteIndex(index)
        setSubscription((prevSubscription) => [
            ...prevSubscription,
            {
                name: `${user?.leaderCommunity?.name} Subscription Tier ${prevSubscription.length + 1
                    }`,
                monthlyfee: "",
                yearlyfee: "",
                tradingCapital: "",
            },
        ]);
    };

    const handleDeleteTier = (index) => {
        setSubscription((prevSubscription) => {
            // Create a copy of the current subscription array
            const newSubscription = [...prevSubscription];

            // Remove the tier at the specified index
            newSubscription.splice(index, 1);

            // Update the state with the modified array
            setSubscription(newSubscription);
            dispatch(setEditable(false))

            return newSubscription;
        });
    };

    useEffect(() => {
        if (user?.leaderCommunity) {
            if (user?.leaderCommunity?.subscription?.length > 0) {
                setSubscription(user?.leaderCommunity?.subscription);
            }
            setEstimatedAmount(user?.leaderCommunity?.estimatedAmount || "");
            setTagData(user?.leaderCommunity?.pair || []);
        }
    }, [user]);

    const removeTagData = (indexToRemove) => {
        setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
    };
    const addTagData = (event) => {
        if (event.target.value !== "") {
            setTagData([...tagData, event.target.value]);
            event.target.value = "";
        }
    };

    useEffect(() => {
        if (user?.firstVisit) {
            setShow(true);
        }
        if (!user?.firstVisit && user?.leaderCommunity?.subscription?.length > 0) {
            const activeSubscription = user.leaderCommunity.subscription.filter((tier) => tier.isActive);
            activeSubscription.sort((a, b) => a.name.localeCompare(b.name));

            setSubscription(activeSubscription);  // Set only the active subscriptions
        }
    }, [user]);

    const yesAction = async (index) => {
        dispatch(setLoading(true));
        setShowModal(false);
        console.log(subscription[index]?._id, "SubcriptionID")
        if (!subscription[index]?._id) {
            handleDeleteTier(index)
            dispatch(setLoading(false));
            toastify("Subscription deleted successfully!", "success");
            return
        }
        const response = await api("post", "users/deleteSubscription", {
            subscriptionId: subscription[index]?._id,
        })

        if (response?.status) {
            const response = await api("get", "users/index");
            if (response?.status) {
                dispatch(updateUser(response?.data?.user));
            }
            toastify("Subscription deleted successfully!", "success");
        } else {
            // toastify(response?.message);
        }

        dispatch(setLoading(false));
    }

    const noAction = () => {
        setShowModal(false);
    };

    // useEffect(() => {
    //     document.addEventListener('click', () => {
    //         if (tab === "subscription") {
    //             debugger
    //             return toastify("Please complete your onboarding", "error");
    //         }
    //     });
    //     return () => {
    //         document.removeEventListener('click', () => {
    //             console.log("hello")
    //         });
    //     };
    // }, []);

    // const handleClick = (event) => {
    //     if (user?.firstVisit && tab === "subscription") {
    //         toastify("Please complete your onboarding", "error");
    //     }
    // };

    const handleInputChange = (value, key, tierIndex) => {
        setSubscription((prevSubscription) => {
            const newValues = [...prevSubscription];

            // Ensure the tier object exists
            if (!newValues[tierIndex]) {
                newValues[tierIndex] = {
                    name: "",
                    monthlyfee: "",
                    yearlyfee: "",
                    tradingCapital: "",
                };
            } else if (key === "pair") {
                console.log("Pair", key, value);
                newValues[tierIndex] = {
                    ...newValues[tierIndex],
                    pair: [...newValues[tierIndex].pair, value],
                };
            } else {
                newValues[tierIndex] = {
                    ...newValues[tierIndex],
                    [key]: value,

                };
            }
            return newValues;
        });
    };



    function checkTradingCapital(data) {
        for (const item of data) {

            if (item.monthlyfee === undefined || item.monthlyfee === "") {
                dispatch(setEditable(false))
                return {
                    status: false,
                    message: "Monthly fee must not be null or empty"
                };

            }

            if (item.yearlyfee === undefined || item.yearlyfee === "") {
                dispatch(setEditable(false))
                return {
                    status: false,
                    message: "Yearly fee must not be null or empty"
                };

            }

            if (item.tradingCapital === undefined || item.tradingCapital === "" || parseFloat(item.tradingCapital) <= 0) {
                dispatch(setEditable(false))
                return {
                    status: false,
                    message: "Max Trading Capital can't be zero or empty"
                };

            }
        }
        return {
            status: true,
            message: ""
        };
    }

    const submit = async () => {
        // dispatch(setLoading(true));
        dispatch(setEditable(true))
        setEditableIndex(null)
        // Validate input fields
        // const isValid = subscription.every((tier) => {
        //     return (
        //         tier.name &&
        //         tier.monthlyfee !== undefined &&
        //         tier.yearlyfee !== undefined &&
        //         tier.tradingCapital !== undefined
        //     );
        // });

        if (
            !subscription.length > 0
            // ||
            // !tagData.length > 0
            // estimatedAmount === ""
        ) {
            toastify("Please fill atleast 1 tier.", "error");
            // dispatch(setLoading(false));
            return;
        }

        // Format the numeric fields to remove leading zeros

        const formattedSubscription = subscription.map((tier) => ({
            ...tier,
            monthlyfee: tier.monthlyfee
                ? parseInt(tier.monthlyfee, 10).toString()
                : tier.monthlyfee,
            yearlyfee: tier.yearlyfee
                ? parseInt(tier.yearlyfee, 10).toString()
                : tier.yearlyfee,
            tradingCapital: tier.tradingCapital && !isNaN(tier.tradingCapital)
                ? parseInt(tier.tradingCapital, 10).toString()
                : tier.tradingCapital,
        }));

        console.log(formattedSubscription, "tradoingcapital")

        // Filter out undefined objects from the subscription array
        const filteredSubscription = formattedSubscription.filter(
            (tier) => tier.name
        );

        const isValid = checkTradingCapital(filteredSubscription);

        if (!isValid.status) {
            toastify(`${isValid.message}`, "error");
            return
        }

        // Prepare the data to send to the backend
        const updatedSubscription = {
            subscription: filteredSubscription,
            pair: tagData, // Assuming tagData is an array
            // estimatedAmount,
        };
        dispatch(setLoading(true))
        console.log("updatedSubscription", updatedSubscription);

        try {
            // Make API call to send data to the backend
            const response = await api("post", "users/submitSubscription", {
                updatedSubscription,
            });
            if (response?.status) {
                dispatch(setLoading(false))
                dispatch(setShowConnectionSuccessModal(true))
                // Update user data and handle other logic
                const response2 = await api("get", "users/index");
                if (response2.status) {
                    const { onboarding, firstVisit } = response2?.data?.user;
                    if (onboarding?.subscribed && firstVisit) {
                        const isValid = checkTradingCapital(filteredSubscription);

                        if (!isValid.status) {
                            toastify(`${isValid.message}`, "error");
                            return
                        }

                        const response3 = await api("post", "users/update", {
                            onboardingStep: "connection",
                        });
                        if (response3.status && response?.status && response2.status) {
                            console.log("connectionnnn")
                            setTab("connection");
                            dispatch(updateUser(response3?.data?.user));
                            dispatch(setShowConnectionSuccessModal(false))
                            // setTimeout(() => {
                            //     dispatch(setShowConnectionSuccessModal(false))
                            //     dispatch(updateUser(response2?.data?.user));
                            // }, 2000);
                            toastify("Subscription added successfully.", "success");
                        }
                    } else if (!firstVisit) {
                        toastify("Subscription updated successfully.", "success");
                        // dispatch(setLoading(false))
                        setTimeout(() => {
                            dispatch(setShowConnectionSuccessModal(false))
                            dispatch(updateUser(response2?.data?.user));
                        }, 2000);
                    }
                }
            } else {
                toastify(response?.message);
                console.log(response?.message);
            }
        } catch (error) {
            console.error("Error submitting subscription:", error);
            toastify("An error occurred while submitting subscription.", "error");
        }

        // dispatch(setLoading(false));
    };

    const handleEdit = (index) => {
        setEditableIndex(index);
        dispatch(setEditable(false));
        // Use setTimeout to ensure that the input is focused before selecting text
        // Create a temporary input element to force the keyboard to open on iOS
        let userAgent = navigator?.userAgent;
        let tempInput = null;
        if (!userAgent?.includes("Firefox")) {
            // console.log("fireBox-Browser");
            tempInput = document.createElement("input");
            document.body.appendChild(tempInput);
            tempInput.className = "hidden-input";
            tempInput.focus();
        }

        setTimeout(() => {
            if (tempInput) {
                tempInput?.remove();
            }
            if (inputRef?.current) {
                inputRef?.current?.focus();
            }
        }, 0);

        console.log(subscription, "subscription");
    };


    return (
        <>
            {
                loaders?.showConnectionSuccessModal ? (
                    <AuthLoader component="connectionsuccess" closeHeadline="Subscription Created!" />) :
                    <div className="subscription subscriptionJs">
                        {/* {show && (
                <OnboardingModal
                    noAction={() => {
                        setShow(false);
                    }}
                    heading={
                        <>
                            Ok, let’s get you set up. <br />
                            Please complete each of the sections above starting
                            with this section.
                        </>
                    }
                    handleClick={() => { toastify("Please complete your onboarding") }}
                />
            )} */}
                        {!user?.firstVisit && <div className="title">
                            <h3>Subcriptions</h3>

                            <p>
                                You need to have minimum 1 subscription tier to launch your copy trade.
                                <br className="d-lg-block d-md-block d-none" />
                                We charge subscribers $15 on top of your fee.
                            </p>
                        </div>}
                        {user?.firstVisit && <div className="title">
                            <h3>Create your subcription</h3>

                            <p>
                                To launch your copy trade you need to define how much you want to charge your users.
                                You need minimum 1 subscription tier.
                                <br className="d-lg-block d-md-block d-none" />
                                We charge subscribers $15 on top of your fee.
                            </p>
                        </div>}
                        <div className="tier mx-691">
                            {/* <h3>Pricing</h3> */}
                            {subscription.map((tier, index) => {

                                return (
                                    <>
                                        <p className="cl-tier-heading">
                                            Tier {` ${index + 1}`}
                                        </p>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "nowrap",
                                            }}
                                            key={index}
                                        >
                                            {/* {index > 0 && (
                                                <img
                                                    src={closeIcon}
                                                    alt="Delete"
                                                    className="subclose-icon cursor-pointer"
                                                    onClick={() => handleDeleteTier(index)}
                                                />
                                            )} */}

                                            <div className="me-2">
                                                <div>
                                                    <label className="cl-tier-label d-flex mb-2">Price</label>
                                                    <input
                                                        ref={editableIndex === index ? inputRef : null}
                                                        type="text"
                                                        autoComplete="new-password"
                                                        placeholder="Monthly"
                                                        className="form-control placeholder-black"
                                                        style={{ color: 'inherit' }}
                                                        disabled={user?.firstVisit ? false : editableIndex !== index}
                                                        id={`floatingInput_${index}`} // Use a unique ID for each input
                                                        value={
                                                            tier?.monthlyfee !== undefined
                                                                ? tier?.monthlyfee === ""
                                                                    ? ""
                                                                    : `$${tier?.monthlyfee}`
                                                                : ""
                                                        }
                                                        onChange={(val) => {
                                                            console.log("valueInsidee", val.target.value);
                                                            if (val.target) {
                                                                const inputWithoutDollar = val.target.value.replace(
                                                                    /^\$/,
                                                                    ""
                                                                );
                                                                const isValidNumber =
                                                                    numberRegex.test(inputWithoutDollar) ||
                                                                    inputWithoutDollar === "";

                                                                if (isValidNumber && inputWithoutDollar.length <= 5) {
                                                                    handleInputChange(
                                                                        inputWithoutDollar,
                                                                        "monthlyfee",
                                                                        index
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <span className="cl-tier-label">
                                                    Subs pay:<span className="me-1"></span>
                                                    {tier?.monthlyfee && parseInt(tier?.monthlyfee) + 15}
                                                </span>
                                            </div>
                                            <div className="">
                                                <div>
                                                    <label className="cl-tier-label d-flex mb-2">Price</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Yearly"
                                                        style={{ color: 'inherit' }}
                                                        className="form-control placeholder-black"
                                                        autoComplete="new-password"
                                                        disabled={user?.firstVisit ? false : editableIndex !== index}
                                                        id={`floatingInput_${index + 1}`} // Use a unique ID for each input
                                                        value={
                                                            tier?.yearlyfee !== undefined
                                                                ? tier?.yearlyfee === ""
                                                                    ? ""
                                                                    : `$${tier?.yearlyfee}`
                                                                : ""
                                                        }
                                                        onChange={(val) => {
                                                            const inputWithoutDollar = val.target.value.replace(
                                                                /^\$/,
                                                                ""
                                                            );
                                                            const isValidNumber =
                                                                numberRegex.test(inputWithoutDollar) ||
                                                                inputWithoutDollar === "";

                                                            if (isValidNumber && inputWithoutDollar.length <= 5) {
                                                                handleInputChange(
                                                                    inputWithoutDollar,
                                                                    "yearlyfee",
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <span className="cl-tier-label">
                                                    Subs pay:<span className="me-1"></span>
                                                    {tier?.yearlyfee && parseInt(tier?.yearlyfee) + 180}
                                                </span>
                                            </div>
                                            <div className="ms-2">
                                                <div>
                                                    <label className="cl-tier-label d-flex align-items-center justify-content-between  mb-2">Max. Trading capital
                                                        <div
                                                            onMouseEnter={() => setTradingCapitalInfoIndex(index)}
                                                            onMouseLeave={() => setTradingCapitalInfoIndex(null)}
                                                            style={{ width: "15px", height: "15px" }}
                                                            className="d-lg-block d-md-block d-none"
                                                        >
                                                            <img
                                                                style={{ width: "15px", height: "15px", top: "-1px", position: 'relative', }}
                                                                className="info-tier-icon"
                                                                src={infoIcon}
                                                                alt="Info"
                                                            />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="$"
                                                        style={{ color: 'inherit' }}
                                                        className="form-control"
                                                        disabled={user?.firstVisit ? false : editableIndex !== index}
                                                        value={
                                                            tier?.tradingCapital !== undefined
                                                                ? tier?.tradingCapital === "" // Empty value
                                                                    ? ""
                                                                    : tier?.tradingCapital.toUpperCase() === "UNLIMITED" // Check for "UNLIMITED"
                                                                        ? tier?.tradingCapital
                                                                        : !isNaN(tier?.tradingCapital) // Check if it's a number
                                                                            ? `$${tier?.tradingCapital}` // Add $ for numeric values
                                                                            : tier?.tradingCapital // Keep it as is for characters
                                                                : ""
                                                        }
                                                        maxLength={9} // Set the maximum length to 9 characters
                                                        onBlur={(val) => {
                                                            const inputValue = val.target.value.trim();
                                                            const inputWithoutDollar = inputValue.replace(/^\$/, ""); // Remove $ if present

                                                            // Check if the input is "UNLIMITED" or a valid number
                                                            const isUnlimited = inputWithoutDollar.toUpperCase() === "UNLIMITED";
                                                            const isNumber = !isNaN(inputWithoutDollar) && inputWithoutDollar !== "";

                                                            if (isUnlimited) {
                                                                handleInputChange("UNLIMITED", "tradingCapital", index);
                                                            } else if (isNumber) {
                                                                const roundedValue = Math.round(parseFloat(inputWithoutDollar));
                                                                handleInputChange(`${roundedValue}`, "tradingCapital", index); // Store number without $
                                                            } else {
                                                                handleInputChange("", "tradingCapital", index);
                                                                toastify("Please enter a valid number or 'Unlimited'");
                                                            }
                                                        }}
                                                        onChange={(val) => {
                                                            const inputValue = val.target.value;
                                                            const inputWithoutDollar = inputValue.replace(/^\$/, ""); // Remove $ for numeric input
                                                            console.log(inputWithoutDollar, "inputtt")

                                                            // Allow all alphanumeric inputs
                                                            handleInputChange(inputWithoutDollar, "tradingCapital", index);
                                                        }}
                                                    />


                                                    <div
                                                        onMouseEnter={() => setTradingCapitalInfoIndex(index)}
                                                        onMouseLeave={() => setTradingCapitalInfoIndex(null)}
                                                        style={{ width: "15px", height: "15px" }}
                                                        className="d-lg-none d-md-none float-end"
                                                    >
                                                        <img
                                                            style={{ width: "15px", height: "15px", top: "-1px", position: 'relative', }}

                                                            className="info-tier-icon"
                                                            src={infoIcon}
                                                            alt="Info"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {tradingCapitalInfoIndex === index && (
                                            <div
                                                className="cl-tier-modal p-2 bg-black text-white text-sm rounded-lg shadow-lg"
                                                style={{
                                                    width: "15rem",
                                                    // marginLeft: "15rem",
                                                    // marginBottom: "1rem",
                                                    position: "absolute",
                                                    zIndex: 9999,
                                                }}
                                            >
                                                <p className="m-2">
                                                    The maximum trading capital is the amount of capital your subscribers can have on the connected trading account. This makes it possible for you to charge more if subscribers have more capital.
                                                </p>
                                            </div>
                                        )}

                                        <div className="cl-iter-action-box">
                                            <button className="edit-btn" onClick={() => handleEdit(index)}>
                                                <img src={edit} alt="" />
                                            </button>
                                            {/* <button className="del-btn" onClick={() => handleDeleteTier(index)}>
                                                <img src={del} alt="" />
                                            </button> */}
                                            <button className="del-btn" onClick={() => {
                                                console.log(deleteIndex, "deleteIndexxx")
                                                setShowModal(true)
                                                setDeleteIndex(index)
                                            }}>
                                                <img src={del} alt="" />
                                            </button>
                                        </div>

                                        <div
                                            style={{
                                                textAlign: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <hr
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #c1c1c1",
                                                    margin: "1rem 0",
                                                }}
                                            />
                                        </div>
                                    </>

                                )
                            })}


                            {/* <h3>2. What are you mainly trading?</h3>
                <div className="card altCard">
                    <div className="block">
                        <label>Fx. ALTs/USDT+enter</label>
                        <div className="tag-input">
                            <ul className="tags">
                                {tagData?.map((tag, index) => (
                                    <li key={index} className="tag">
                                        <span className="tag-title">{tag}</span>
                                        <span
                                            className="tag-close-icon"
                                            onClick={() => removeTagData(index)}
                                        >
                                            <img src={Cross} alt="cross" />
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <input
                                type="text"
                                className="fs15"
                                onKeyUp={(event) =>
                                    event.key === "Enter"
                                        ? addTagData(event)
                                        : null
                                }
                                // placeholder="Press add a currency"
                                onChange={(val) => {
                                    handleInputChange(val.target.value, "pair");
                                }}
                            />
                        </div>
                    </div>
                </div> */}

                            {/* <h3>3. Last 90 days trading would have turned $1000 into?</h3>
                <div className="card">
                    <div className="block">
                        <div className="form-floating">
                            <input
                                type="text"
                                placeholder="$"
                                className="form-control"
                                id="floatApprox"
                                value={
                                    estimatedAmount !== undefined
                                        ? estimatedAmount === ""
                                            ? ""
                                            : `$${estimatedAmount}`
                                        : ""
                                }
                                onChange={(val) => {
                                    const inputWithoutDollar =
                                        val.target.value.replace(/^\$/, ""); // Remove $ sign if present
                                    const isValidNumber =
                                        numberRegex.test(inputWithoutDollar) ||
                                        inputWithoutDollar === "";

                                    if (isValidNumber) {
                                        setEstimatedAmount(inputWithoutDollar);
                                    }
                                }}
                            />
                            <label htmlFor="floatApprox">
                                Approx result in USD
                            </label>
                        </div>
                        <span className="color-transparent">.</span>
                    </div>
                </div> */}


                            <div className="d-lg-flex d-md-flex aling-items-center justify-content-between">
                                <div
                                    style={{

                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginTop: "1rem",
                                    }}
                                    className="text-start d-block"
                                >
                                    {subscription.length < 5 && (
                                        <div
                                            className="addTier"
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                margin: "0",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => { handleAddTier(subscription.length) }}
                                        >
                                            <img
                                                src={AddIcon}
                                                className="addIcon"

                                            />
                                            <p>Add extra tier</p>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {/* editable condition remove bcz we also remove edit button icon */}
                                    {user?.firstVisit &&
                                        <div className="d-flex justify-content-end"
                                            style={{
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <button
                                                className="btn cyan-btn fs13 tier-save-btn"
                                                style={{ fontSize: "12px" }}
                                                // style={{
                                                //     width: "32rem",
                                                //     height: "4rem",
                                                //     textAlign: "center",
                                                //     borderRadius: "12px",
                                                // }}
                                                onClick={submit}
                                            >
                                                {"Next"}
                                            </button>
                                        </div>
                                    }

                                    {/* {user?.firstVisit && (
                                        <div className="d-flex justify-content-end"
                                            style={{
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <button className="btn cyan-btn f13 tier-save-btn" style={{ fontSize: "12px" }} onClick={submit}>
                                                {user?.firstVisit ? "Next" : "Save Changes"}
                                            </button>
                                        </div>
                                    )} */}
                                    {!user?.firstVisit && !editable && (
                                        <div className="d-flex justify-content-end"
                                            style={{
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <button className="btn cyan-btn f13 tier-save-btn" style={{ fontSize: "12px" }} onClick={submit}>
                                                Save Changes
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        {showModal && (
                            <DeleteMember
                                noAction={noAction}
                                yesAction={() => yesAction(deleteIndex)} // Pass the delete index here
                                text="Delete subscription"
                                subtext="Are you sure you want to delete this pricing?"
                            />
                        )}
                        <RenderLinks page="copyTradeSubscription" />
                    </div>
            }
        </>
    );
}

export default Subscription;
