export const simplifyTradingVolume = (value) => {
  console.log(value, "incoming Value");

  // Ensure the value is a number
  value = Number(value);

  if (value === 0) {
    return "0.00";
  } else if (value >= 1000000) {
    const millionValue = (value / 1000000).toFixed(2);
    return millionValue.replace(/\.?0*$/, "") + "M";
  } else if (value >= 1000) {
    const thousandValue = (value / 1000).toFixed(2);
    return thousandValue.replace(/\.?0*$/, "") + "K";
  } else {
    return value.toFixed(2);
  }
};
